import React, { ChangeEvent, ReactNode } from 'react'
import * as S from './Review.styles'
import { Review } from '../../common/@types/interfaces'
import { ReviewType } from '../../common/@types/enums'
import { IssueType } from './components/Issue/Issue.helper'
import { useMoveChips } from './Review.helper'
import { getAppointmentText, getRateText } from '../../helpers/Review.helper'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import RateView from './components/RateView'
import Issues from './components/Issue'

interface Props {
  review: Review
  feedback: string | null
  imageUrl?: string
  reviewType: ReviewType
  showSubmit?: boolean
  children?: ReactNode | ReactNode[]
  showImprovements?: boolean
  onRatingUpdate: (rating: number) => void
  onSubmit: () => void
  surveyEntities?: string[]
  setFeedback: (value: string) => void
  onAddIssue: (type: IssueType) => void
  onDeleteIssue: (id: string) => void
}

const ReviewScreen = ({
  review,
  feedback,
  reviewType,
  imageUrl,
  showImprovements,
  showSubmit,
  onRatingUpdate,
  onSubmit,
  setFeedback,
  onAddIssue,
  onDeleteIssue,
  surveyEntities,
  children,
}: Props) => {
  useMoveChips(review.rating !== 0)

  const { t } = useTranslation()
  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFeedback(event.target.value)
  }

  const getAppointmentRatingAsString = () => {
    if (review.rating === 0) {
      return t(`${getAppointmentText(review.rating, ReviewType.PRACTITIONER)}`, {
        practitionerName: review.entity.name,
      })
    }
    return t(`${getAppointmentText(review.rating, ReviewType.PRACTITIONER)}`) || ''
  }

  const clinicText = {
    header: `${t('almost_done')}`,
    experienceSubHeader: `${t('help_understand_us.general')}`,
    helpUnderstand: t(`${getAppointmentText(review.rating, ReviewType.CLINIC)}`) || '',
    rating: t(`${getRateText(review.rating)}`),
    submitButton: `${t('submit_rating')}`,
    feedbackPlaceholder: `${t('feedback.clinic')}`,
  }

  const practitionerText = {
    header: `${t('your_opinion')}`,
    experienceSubHeader: `${t('help_understand_us.general', { postProcess: 'markdown' })}`,
    helpUnderstand: getAppointmentRatingAsString(),
    rating: t(`${getRateText(review.rating)}`),
    submitButton: `${t('last_step')}`,
    feedbackPlaceholder: `${t('feedback.practitioner')}`,
  }

  const text = reviewType === ReviewType.PRACTITIONER ? practitionerText : clinicText

  return (
    <S.Wrapper>
      <S.Root elevation={0} square={false}>
        <S.Header>{text.header}</S.Header>
        <S.Subheader dangerouslySetInnerHTML={{ __html: text.experienceSubHeader }} />
        {surveyEntities && (
          <S.EntitiesList>
            {surveyEntities?.map((name) => (
              <S.Entity>{name}</S.Entity>
            ))}
          </S.EntitiesList>
        )}
        <S.Avatar alt={review.entity.name} src={imageUrl} />
        <S.QuestionText>{text.helpUnderstand}</S.QuestionText>
        <RateView
          referenceId={review.reviewReferenceId}
          reviewRating={review.rating}
          onUpdate={onRatingUpdate}
          starSize="large"
          showStarLabel={review.rating !== 0 ? 'hover' : 'static'}
          staticLabelValues={[1, 5]} // showing static labels on values
        />

        <S.ReviewForm invisible={!showImprovements && !showSubmit}>
          {showImprovements && (
            <>
              <S.CommentText>{text.rating}</S.CommentText>

              <Issues
                aria-hidden={!showImprovements}
                selected={review.issues}
                category={review.reviewCategory}
                addChip={showImprovements ? onAddIssue : () => {}}
                deleteChip={showImprovements ? onDeleteIssue : () => {}}
              />

              <S.TextField
                aria-hidden={!showImprovements}
                variant="outlined"
                id={`review-comment-field`}
                multiline
                minRows={3}
                value={feedback}
                label={text.feedbackPlaceholder}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleTextChange}
                disabled={!showImprovements}
              />
            </>
          )}

          {children}

          {showSubmit && (
            <S.ButtonContainer>
              <Button disabled={!showImprovements && !showSubmit} fullWidth onClick={onSubmit}>
                {text.submitButton}
              </Button>
            </S.ButtonContainer>
          )}

          <S.PageCount>{`${review.entityType === ReviewType.PRACTITIONER ? '1' : '2'}/2`}</S.PageCount>
        </S.ReviewForm>
      </S.Root>
    </S.Wrapper>
  )
}

export default ReviewScreen
