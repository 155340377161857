import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckmarkInBlue } from '../../assets/icons/check-mark-in-blue.svg'
import * as S from './Finish.styles'

const Finish = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>{t('thank_you')}</S.Title>
      <S.Subtitle>{t('finish.description')}</S.Subtitle>
      <S.Frame>
        <CheckmarkInBlue />
      </S.Frame>
    </S.Container>
  )
}

export default Finish
