import React, { useCallback, useEffect, useState } from 'react'
import * as api from '../../common/api/happy'
import { submitSurvey } from '../../common/api/happy'
import ReviewScreen from '../../screens/Review/Review'
import { ReviewType } from '../../common/@types/enums'
import useReviewContext, { getClinicReview, UpdateReviewProps } from '../../store/ReviewContext'
import { useDebounce } from 'use-debounce'
import clinicImageUrl from '../../assets/icons/clnic-pic.jpg'
import {
  handleAddIssue,
  handleDeleteIssue,
  handleRedirectToThirdPartySite,
  handleRedirectWithScreen,
  useAddIssueMutation,
  useDeleteIssueMutation,
} from '../../helpers/Review.helper'
import useSurveyContext from '../../store/SurveyContext'
import getPublicAsset from '../../helpers/getPublicAsset'
import { useQueryParameterContext } from '../../providers/QueryParameterProvider'
import { Survey } from '../../common/@types/interfaces'
import RedirectScreen from '../../screens/Redirect/Redirect'
import noOp from '../../helpers/noOp'

const ReviewClinic: React.FC = () => {
  const queryParamsContext = useQueryParameterContext()
  const surveyContext = useSurveyContext()
  const reviewContext = useReviewContext()
  const review = getClinicReview(reviewContext)
  const [feedback, setFeedback] = useState<string | null>(review?.feedback || null)
  const [showRedirectScreen, setShowRedirectScreen] = useState(false)

  const [feedbackDebounce] = useDebounce(feedback, 1000)

  const handleShowRedirectScreen = () => {
    setShowRedirectScreen(true)
  }

  const handleUpdateFeedback = useCallback(() => {
    if (review && feedbackDebounce !== review.feedback)
      api.updateReview({
        referenceId: review.reviewReferenceId,
        feedback: feedback,
        entityType: review.entityType,
      })
    // eslint-disable-next-line
  }, [feedbackDebounce])

  const handleSubmit = () => {
    const referenceId = surveyContext.survey?.surveyReferenceId
    if (referenceId) {
      submitSurvey(referenceId).then((survey: Survey) => {
        surveyContext.setSurvey(survey)
        handleRedirectWithScreen(queryParamsContext.embedded, handleShowRedirectScreen, survey.redirectToUrl)
      })
    }
  }

  const updateReview = ({ value, feedback, issues }: Omit<UpdateReviewProps, 'referenceId'>) => {
    if (review) {
      reviewContext.updateReview({
        referenceId: review.reviewReferenceId,
        value,
        feedback,
        issues,
      })
    }
  }

  const { mutateAsync: addIssue, isLoading: isAddIssueLoading } = useAddIssueMutation(review, updateReview)

  const { mutateAsync: deleteIssue, isLoading: isDeleteIssueLoading } = useDeleteIssueMutation()

  const handleUpdateRating = (referenceId: string, entityType: ReviewType) => (rating: number) => {
    api
      .updateReview({
        referenceId: referenceId,
        rating: rating,
        entityType: entityType,
      })
      .then(() => {
        updateReview({
          value: rating,
        })
      })
  }

  useEffect(() => {
    handleUpdateFeedback()
  }, [handleUpdateFeedback])

  if (!review) return <div />

  const handleRedirect = () =>
    handleRedirectToThirdPartySite(surveyContext.survey?.redirectToUrl || '', queryParamsContext.embedded)

  if (showRedirectScreen) {
    return <RedirectScreen onRedirect={handleRedirect} />
  }

  return (
    <ReviewScreen
      review={review}
      feedback={feedback}
      surveyEntities={surveyContext.survey?.reviews.map((survey) => survey.entity.name)}
      reviewType={ReviewType.CLINIC}
      showImprovements={review.rating > 0}
      showSubmit={review.rating > 0}
      imageUrl={
        getPublicAsset(clinicImageUrl, 'assets/icons/clnic-pic.jpg') // static clinic image
      }
      onRatingUpdate={handleUpdateRating(review.reviewReferenceId, review.entityType)}
      onSubmit={handleSubmit}
      setFeedback={setFeedback}
      onAddIssue={isAddIssueLoading ? noOp : handleAddIssue(review.reviewReferenceId, ReviewType.CLINIC, addIssue)}
      onDeleteIssue={
        isDeleteIssueLoading ? noOp : handleDeleteIssue(review, ReviewType.CLINIC, deleteIssue, updateReview)
      }
    />
  )
}

export default ReviewClinic
