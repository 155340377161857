import { Typography, Container as ContainerBase, Box } from '@mui/material'

import { styled } from '@mui/material'

export const Container = styled(ContainerBase)(({ theme }) => ({
  maxWidth: '592px',
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  padding: theme.spacing(3),
  marginTop: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography['Lato H2'],
}))

Title.defaultProps = {
  variant: 'Lato H4',
}

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography['Lato H4'],
  marginTop: theme.spacing(2),
}))

Subtitle.defaultProps = {
  variant: 'Lato Body 1',
}

export const Frame = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 110,
})
