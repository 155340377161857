import { matchesDesktop, matchesMobile } from '../../helpers/breakpoints'
import { Box, Paper, TextField as TextFieldBase, Typography } from '@mui/material'
import { Avatar as AvatarBase, colors } from '@dentalux/ui-library-core/cjs'

import { styled } from '@mui/material'

export const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Root = styled(Paper)(({ theme }) => ({
  width: 1172,
  borderRadius: '12px',
  justifyContent: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',

  [matchesMobile(theme)]: {
    padding: '0px 12px',
    overflowX: 'hidden',
  },
}))

export const Avatar = styled(AvatarBase)(({ theme }) => ({
  margin: theme.spacing(3, 'auto'),

  '&.MuiAvatar-root': {
    width: 120,
    height: 120,
  },
}))

export const Header = styled(Typography)(({ theme }) => ({
  marginTop: 32,

  [matchesMobile(theme)]: {
    ...theme.typography['Lato H5 Bold'],
  },
}))

Header.defaultProps = {
  variant: 'Lato H4 Bold',
}

export const Subheader = styled(Typography)(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  marginTop: theme.spacing(2),

  [matchesMobile(theme)]: {
    ...theme.typography['Lato Body 2'],
  },
}))

Subheader.defaultProps = {
  variant: 'Lato Body 1',
}

export const QuestionText = styled(Typography)(({ theme }) => ({
  [matchesMobile(theme)]: {
    ...theme.typography['Lato Body 2'],
  },
}))

QuestionText.defaultProps = {
  variant: 'Lato Body 1',
}

export const ReviewForm = styled(Box, {
  shouldForwardProp: (prop: string) => !['invisible'].includes(prop),
})<{ invisible: boolean }>(({ invisible }) => ({
  display: 'flex',
  flexDirection: 'column',

  ...(invisible && {
    visibility: 'hidden',
  }),
}))

export const CommentText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
}))

CommentText.defaultProps = {
  variant: 'Lato H4 Bold',
}

export const TextField = styled(TextFieldBase)(({ theme }) => ({
  marginTop: 24,
  alignSelf: 'center',

  [matchesDesktop(theme)]: {
    width: 380,
  },

  [matchesMobile(theme)]: {
    width: '100%',
  },
}))

export const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  alignSelf: 'center',
  width: '100%',
  maxWidth: 371,

  [matchesMobile(theme)]: {
    paddingBottom: theme.spacing(2.5),
  },
}))

export const PageCount = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: colors.grey[400],
}))

PageCount.defaultProps = {
  variant: 'Lato Emphasized 2',
}

export const EntitiesList = styled('ul')({
  alignSelf: 'center',
  justifySelf: 'center',
  minWidth: 400,
})

export const Entity = styled('li')({
  textAlign: 'left',
})
